import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import { StaticImage } from "gatsby-plugin-image";
import loadable from "@loadable/component"
import PropertyCard from "../PropertyCard/PropertyCard";
import ImageModule from "../../modules/image-render";
import { PageLinks } from "../../common/site/page-static-links";
import { ApiRequest } from "../../common/utils/api_request_utils";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/styles/_index.scss';
import GGFXImage from "../../modules/GGFXImage";
import CurrencyPrice from "../CurrencyPrice/CurrencyPrice";
import { dateFormat } from "../../utils/common/utils";
const Slider = loadable(() => import("react-slick"));
const { Site_Vars } = require("../../common/site/config");

const CommunityProperties = ({properties}) => {

    //const [processedImages, setProcessedImages] = useState([])

    // Slider settings
    let settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false,
                    dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    speed: 400,
                    arrows: false,
                    dots: true,
                },
            },
        ],
    }
    // Slider settings
    
    return (
        <React.Fragment>
            {properties.length > 0 &&
                <section className={`featured-properties-wrapper similar-properties`}>
                    <Container>
                        <Row>
                            <Col>
                                <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={10}>
                                    <h2 className={``}>Community Properties</h2>
                                    {/* <div className={`slider-text ${props.tag === "property-details" ? "" : "text-center"}`}><span>Struggling to find a property?</span> <Link to={`/${PageLinks.enquiry}/`} className="link-underline">Get in touch</Link> and we'll help you find your ideal property.</div> */}
                                </ScrollAnimation>
                                <Slider className="featured-properties-slider" {...settings}>
                                    {properties?.map((item, i) => {
  const today = new Date();
  const checkInDate = today && today 
// Get the received date
const resd = item?.availabilities?.length > 0 && item?.availabilities
const receivedDate = resd?.length > 0 && new Date(resd[0]?.StartDate);
let showDate;
if(checkInDate && receivedDate && checkInDate > receivedDate){
  showDate = checkInDate
}
else if (receivedDate && receivedDate < today) {
  showDate = today
  // console.log("The received date is a previous date.");
} else {
  showDate = receivedDate
  // console.log("The received date is not a previous date or it's today.");
}
                                                      const parseImage = item?.images && item.images
                                                      const imageUrl = parseImage?.length > 0 && parseImage[0]
                                                      
                                                    //   console.log(item?.ggfx_results)
                                                    //   const ggfx_results= item?.ggfx_results && JSON.parse(item?.ggfx_results)
                                                      
                                        let propid = ''
                                        if (item?.strapi_id)
                                        {
                                            propid = item.strapi_id
                                        }
                                        let details_path = '/holiday-lets-details'
                                        // if (item.search_type == "lettings") {
                                        //     details_path = '/property-to-rent'
                                        // }
                                        // var imagename = "property.images.results";

                                        // let processedImages = JSON.stringify({});
                                        // if (item?.imagetransforms?.images_Transforms) {
                                        //     processedImages = item?.imagetransforms?.images_Transforms;
                                        // }

                                        // var image_all = JSON.parse(item.images.replace('\"', '"'))
                                        return (
                                            <ScrollAnimation className="item-slide featured-properties-slide" animateIn="animate__slideInUp" animateOnce delay={i * 100} offset={10}>
                                                
                                                <div className="property-card-wrapper">
            <div className="property-card-img-zoom">
            {/* <div className="status-bk">
        {item.tags && <div className={`status-tag`}>{item.tags}</div>}
        {item?.building && <div className={`status-tag`}>{titleCase(item?.building?.toString())}</div>}
        </div> */}
                {/* {item.status && <div className={`status-tag ${isLet ? "let" : ""}`}>{item.status}</div>} */}
                <Link to={details_path + '/' + item.slug + '/' + (propid)+'/'}>
                <GGFXImage
                            ImageSrc={item?.images[0]}
                            key=""
                            altText={item?.title}
                            imagetransforms={item?.ggfx_results}
                            renderer="srcSet"
                            imagename="property.images.item"
                            strapiID={item?.strapi_id}
                            className={"banner-img"}
                          />
                {/* <img
          src={imgSrc || noImg}
          alt={item.display_address}
          className="property-img"
        /> */}
                    {/* {props.tag == "no-result" && item.images?.strapi_json_value.length > 0 &&
                        <ImageModule ImageSrc={item.images?.strapi_json_value[0]} altText={""} imagetransforms={props.processedImages} renderer="srcSet" imagename={imagename} strapi_id={item?.strapi_id} classNames="img-fluid" />
                    } */}

                    {/* {props.tag != "no-result" && item?.images.length > 0 &&
                        <img fetchpriority={props.myindexval == 0 ? "low" : "low"} loading={props.myindexval < 6 ? "eager" : "lazy"} src={item?.images[0]['416x300']} alt="banner" className="img-fluid" />
                    }
                    {props.tag != "no-result" && item?.images.length == 0 &&
                        <StaticImage src="../../images/no-image.png" layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="90" className="img-fluid" />
                    } */}
                </Link>
            </div>
            <div className="property-card-text-wrapper">

                <div className="price-details">{item.title}</div>
                    {/* {item?.price_qualifier != "0" && item.search_type !== "lettings" ? item?.price_qualifier : ''} {Site_Vars.default_currency}{item?.price?.toLocaleString()} {item?.price_qualifier != "0" && item.search_type === "lettings" ? item?.price_qualifier : ''}</div> : <div className="price-details">{item.price_qualifier} {Site_Vars.default_currency}{item?.price?.toLocaleString()} {item?.max_price ? ` - ${Site_Vars.default_currency}${item?.max_price?.toLocaleString()}` : ''}</div> */}

                <p className="display-address">
                    <Link to={details_path + '/' + item.slug + '/' + (propid)+'/'}>
                        {item.display_address}
                    </Link>
                </p>
                <div className="d-flex box-bottom">
                    <div className="price">
                    from <span className="txt-b"><CurrencyPrice price={item?.price} /></span>
                    </div>
                    {item?.availabilities?.length > 0 && showDate && (
            <div className="date">
              Available{" "}
              <span className="txt-b">
                {dateFormat(showDate)}
              </span>
            </div>
          )}
                    {/* <div className="date">Available <span className="txt-b">30 Sep 2023</span></div> */}
                </div>
                {/* <div className="property-title">{title}</div> */}
            </div>
        </div>
                                            </ScrollAnimation>
                                        )
                                    }
                                    )}

                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
        </React.Fragment>
    )
}

export default CommunityProperties