import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { StaticImage } from "gatsby-plugin-image"
import "./assets/styles/_index.scss"
// import ImageModule from "../../modules/image-render";
import NoImg from "../../images/noImage.png"
import { dateFormat, titleCase } from "../../utils/common/utils"
// import CurrencyPrice from "../CurrencyPrice/CurrencyPrice"
import GGFXImage from "../../modules/GGFXImage"
import CurrencyPriceNoSYmbol from "../CurrencyPrice/CurrencyPriceNoSYmbol"

const { Site_Vars } = require("../../common/site/config")

const PropertyCard = (props) => {
  let imgSrc = NoImg
  if (props?.data?.images?.length > 0 && props?.data?.images[0]["382x262"]) {
    imgSrc = props?.data?.images[0]["382x262"]
  }

  if (props?.images?.strapi_json_value?.length > 0) {
    imgSrc = props.images.strapi_json_value[0].url
      ? props.images.strapi_json_value[0].url
      : props.images.strapi_json_value[0].srcUrl
  }
  const details_path = "/holiday-lets-details"
  const location = useLocation()
  // if (props.data.search_type == "lettings") {
  //     details_path = '/property-to-rent'
  // }
  // if (props.propertyTypeVal == "new_developments") {
  //     details_path = '/new-home-for-sale'
  // }
  const imagename = "property.images.0.search_results"
  let propid = ""
  if (props?.data?.strapi_id) propid = props.data.strapi_id
  else if (props?.data?.objectID) propid = props.data.objectID

  const propCount = props.data?.title?.slice(0, 1)
  let title =
    isNaN(propCount) &&
    props.data?.title?.slice(0, 1).toUpperCase() + props.data?.title?.slice(1)
  title ||= props?.data?.title

  const isLet = props.data.status === "Let"
  const today = new Date();
  const checkInDate = props?.checkInDate && new Date(props?.checkInDate) 
// Get the received date
const receivedDate = props.data?.availabilities?.length > 0 && new Date(props.data?.availabilities[0]?.StartDate);
let showDate;
if(checkInDate && receivedDate && checkInDate > receivedDate){
  showDate = checkInDate
}
else if (receivedDate && receivedDate < today) {
  showDate = today
  // console.log("The received date is a previous date.");
} else {
  showDate = receivedDate
  // console.log("The received date is not a previous date or it's today.");
}
  return (
    <div className="property-card-wrapper" id={`property_${props.data.crm_id}`}>
      <div className="property-card-img-zoom">
        <div className="status-bk">
        {props.data.tags && <div className={`status-tag`}>{props.data.tags}</div>}
        {props.data?.building && <div className={`status-tag`}>{titleCase(props.data?.building?.toString())}</div>}
        </div>
        <Link to={`${details_path}/${props.data.slug}/${propid}/`}>
          {/* <img
            src={imgSrc || noImg}
            alt={props?.data?.displayAddress}
            className="property-img"
          /> */}
          {props.tag === "no-result" && props.data.images?.strapi_json_value.length > 0 &&
                        // <ImageModule ImageSrc={props.data.images?.strapi_json_value[0]} altText={""} imagetransforms={props.processedImages} renderer="srcSet" imagename={imagename} strapi_id={props.data?.strapi_id} classNames="img-fluid" />
                        <GGFXImage
              ImageSrc={props.data.images?.strapi_json_value[0]}
              key=""
              altText={props?.data?.displayAddress}
              imagetransforms={props?.data?.ggfx_results}
              renderer="srcSet"
              imagename={imagename}
              strapiID={props?.data?.strapi_id}
              className={"banner-img"}
            />
                    }

          {props.tag !== "no-result" && props.data?.images.length > 0 &&
                        // <img fetchpriority={props.myindexval == 0 ? "low" : "low"} loading={props.myindexval < 6 ? "eager" : "lazy"} src={props.data?.images[0]['416x300']} alt="banner" className="img-fluid" />
                         <img
            src={imgSrc || noImg}
            alt={props?.data?.displayAddress}
            className="property-img"
          /> 
                    }
                    {props.tag !== "no-result" && props.data?.images.length == 0 &&
                        <StaticImage src="../../images/no-image.png" layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="90" className="img-fluid" />
                    }
        </Link>
      </div>
      <div className="property-card-text-wrapper">
        <div className="price-details">
          <Link to={`${details_path}/${props.data.slug}/${propid}/`}>
            {title}
          </Link>
        </div>
        {/* {props.data?.price_qualifier != "0" && props.data.search_type !== "lettings" ? props.data?.price_qualifier : ''} {Site_Vars.default_currency}{props.data?.price?.toLocaleString()} {props.data?.price_qualifier != "0" && props.data.search_type === "lettings" ? props.data?.price_qualifier : ''}</div> : <div className="price-details">{props.data.price_qualifier} {Site_Vars.default_currency}{props.data?.price?.toLocaleString()} {props.data?.max_price ? ` - ${Site_Vars.default_currency}${props.data?.max_price?.toLocaleString()}` : ''}</div> */}

        <p className="display-address">
          <Link to={`${details_path}/${props.data.slug}/${propid}/`}>
            {props.data.display_address}
          </Link>
        </p>
        <div className="d-flex box-bottom">
          {props.data?.price &&
          <div className="price">
            from{" "}
            <span className="txt-b">
              {/* {Site_Vars.default_currency} {props.data?.price?.toLocaleString()} */}
              <CurrencyPriceNoSYmbol price={props.data?.price} /> 
              {/* <span>per night</span> */}
              {/* <CurrencyPrice price={props.data?.price} />  */}
            </span>
          </div>}
          {props?.differenceInDays !== 0 && props.data?.price ?
          <div className="date">
          total{" "}
          <span className="txt-b">
          <CurrencyPriceNoSYmbol price={props.data?.price*props?.differenceInDays} />
          {/* <CurrencyPrice price={props.data?.price*props?.differenceInDays} /> */}
          </span>
        </div> : <>
          {props.data?.availabilities?.length > 0 && showDate && (
            <div className="date">
              Available{" "}
              <span className="txt-b">
                {dateFormat(showDate)}
              </span>
            </div>
          )} </>}
        </div>
        {/* <div className="property-title">{title}</div> */}
      </div>
    </div>
  )
}

export default PropertyCard
