import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import { PageLinks } from "../common/site/page-static-links"
import Seo from "../components/seo"
import TileBlock from "../components/TileBlock/TileBlock"
import CommunityProperties from "../components/FeaturedProperties/CommunityProperties"

const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)
const CommunityDetailIntro = loadable(() =>
  import("../components/CommunityDetailIntro/CommunityDetailIntro")
)
const CommunityDetailDesc = loadable(() =>
  import("../components/CommunityDetailDesc/CommunityDetailDesc")
)
const PropertyDetailsMap = loadable(() => import("../components/PropertyDetailsMap/PropertyDetailsMap"));
// const ValuationModule = loadable(() => import("../components/ValuationModule/ValuationModuleGlobal"));
const MoreCommunityModule = loadable(() => import("../components/MoreCommunity/MoreCommunity"))

const NewsDetail = ({ data }, props) => {
  const PageData = data?.strapiAreaGuide
  const MoreCommunity = data?.allStrapiAreaGuide.edges
  const moduleData = data?.strapiPage
  const properties = data?.allProperties?.nodes
  let breadcrumData = {
    parentname: PageLinks.community_label,
    parent: PageLinks.community,
    pagename: PageData.title,
  }


  return (
    <Layout popularSearch="Generic pages">
      <div className="layout-padding-top">
        <BreadcrumbModule {...breadcrumData} />
        <div className="news-details">
          <CommunityDetailIntro {...PageData} />

          <CommunityDetailDesc {...PageData} />
        </div>
        {PageData.latitude && PageData.longitude && <PropertyDetailsMap
          lat={PageData.latitude}
          lng={PageData.longitude}
        />}

        <MoreCommunityModule data={MoreCommunity} />
        <CommunityProperties properties={properties} />
        {moduleData?.add_page_modules?.length > 0 &&
          moduleData.add_page_modules?.map((module, index) => {
            return (
              <>

                {module.strapi_component === "page-modules.tile-block" && (
                  <TileBlock
                    {...module}
                    strapiID={PageData?.strapi_id}
                    ggfx_results={PageData?.ggfx_results}
                    imagetransforms={PageData?.imagetransforms?.internal?.content}
                  />
                )}
              </>
            )
          })}
        {/* <ValuationModule /> */}
       
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiAreaGuide
  // const ldJson = {
  //   "@context": "https://schema.org",
  //   "@type": "NewsArticle",
  //   mainEntityOfPage: {
  //     "@type": "WebPage",
  //     "@id": typeof window !== "undefined" ? window.location.href : "",
  //   },
  //   headline: PageData?.title,
  //   image: PageData?.banner_image ? PageData?.banner_image?.url : "",
  //   datePublished: PageData?.publishedAt,
  //   dateModified: PageData?.updatedAt,
  //   author: {
  //     "@type": "Person",
  //     name: PageData.author
  //       ? PageData.author?.title
  //       : process.env.GATSBY_SITE_NAME,
  //   },

  //   publisher: {
  //     "@type": "Organization",
  //     name: process.env.GATSBY_SITE_NAME,
  //     logo: {
  //       "@type": "ImageObject",
  //       url: `${process.env.GATSBY_SITE_URL}/images/logo.png`,
  //     },
  //   },
  //   description: `Read about ${PageData.title} here and subscribe to our newsletter to stay up-to-date about everything going on at ${process.env.GATSBY_SITE_NAME}.`,
  // }

  return (
    <Seo
      title={PageData.title + " Area Guide"}
      description={`Discover ${PageData.title} with PrimeStay's detailed area guide. Explore local attractions, amenities, and essential insights for living in this vibrant community.`}
    >
      {/* <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
      /> */}
    </Seo>
  )
}

export default NewsDetail

export const query = graphql`
  query ($page_id: Int, $title: String) {
    strapiAreaGuide(strapi_id: { eq: $page_id }) {
      ...AreaGuideFragment
      
      publishedAt(formatString: "YYYY-MM-DD")
      updatedAt(formatString: "YYYY-MM-DD")
    }
    strapiPage(id: { eq: "f73bef28-f3b5-5579-8fdd-f26f07ef68f4" }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_TILE_BLOCK {
          ...TailBlockFragment
        }
        
      }
    }

    allProperties(
            limit: 6
            sort: { fields: createdAt, order: DESC }
           filter: {publish: { eq: true }, address: {address3: {eq: $title}}}
    ) {
          nodes {
            ...PropertyFragment
          }
      }
    
    allStrapiAreaGuide(
      filter: { publish: { eq: true }, strapi_id: { ne: $page_id } }
    ) {
      edges {
        node {
          ...AreaGuideFragment
          tile_image {
            url
          }
        }
      }
    }
  }
`
